import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import mammoth from "mammoth";
import Drawer from "./components/Drawer";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { Subheading } from "components/misc/Headings";
import translateText from "./utils/translateText";
import LanguageSelectors from "./components/LanguageSelectors";

const Label = tw.label`block mb-2 text-sm font-medium text-gray-900 dark:text-white`;
const Input = tw.input`block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400`;

const translateHtmlText = async (htmlString, fromLang, toLang) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;

  const traverseAndTranslate = async (element) => {
    if (element.nodeType === Node.TEXT_NODE) {
      element.textContent = await translateText(
        element.textContent,
        fromLang,
        toLang
      );
    } else if (element.nodeType === Node.ELEMENT_NODE) {
      for (let child of element.childNodes) {
        await traverseAndTranslate(child);
      }
    }
  };

  await traverseAndTranslate(tempDiv);

  return tempDiv.innerHTML;
};

function TranslateDoc() {
  const [content, setContent] = useState("");
  const [translatedContent, setTranslatedContent] = useState("");
  const [fromLang, setFromLang] = useState("en");
  const [toLang, setToLang] = useState("fr");
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const arrayBuffer = e.target.result;
          const result = await mammoth.convertToHtml({ arrayBuffer });
          setContent(result.value);
        } catch (error) {
          console.error("Error reading file:", error);
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };
  useEffect(() => {
    if (content.length > 0) {
      translateHtmlText(content, fromLang, toLang).then((result) =>
        setTranslatedContent(result)
      );
    }
  }, [content, fromLang, toLang]);
  return (
    <AnimationRevealPage>
      <div style={{ paddingLeft: "16rem" }}>
        <Header />
        <Drawer />
        <>
          <LanguageSelectors
            setFromLang={setFromLang}
            setToLang={setToLang}
            fromLang={fromLang}
            toLang={toLang}
          />
          <Label for="file_input">Upload file</Label>
          <Input
            id="file_input"
            type="file"
            accept=".docx"
            onChange={handleFileChange}
          />
          <div>
            <div>
              <Subheading>Original Content</Subheading>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <div>
              <Subheading>Translated Content</Subheading>
              <div dangerouslySetInnerHTML={{ __html: translatedContent }} />
            </div>
          </div>
        </>
        <Footer />
      </div>
    </AnimationRevealPage>
  );
}

export default TranslateDoc;
