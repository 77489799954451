import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as MMenuIcon } from "feather-icons/dist/icons/menu.svg";

import logo from "images/logo.png";
import image1 from "./images/1.jpg";
import image2 from "./images/2.jpg";
import image3 from "./images/3.jpg";
import image4 from "./images/4.jpg";

const Banner = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  @keyframes zoom {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.3);
    }
  }
`;
const Slider = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
`;
const SlideImg = styled.img`
  width: 100%;
  height: 100%;
  animation: zoom 3s linear infinite;
`;
const Overlay = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  position: absolute;
  top: 0;
`;
const Navbar = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  margin: 35px auto;
`;
const Logo = styled.div`
  flex-basis: 15%;
  img {
    width: 120px;
    cursor: pointer;
  }
`;
const MenuIcons = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: end;
`;
const MenuIcon = styled(MMenuIcon)`
  width: 20px;
  margin-left: 40px;
  cursor: pointer;
`;
const Content = styled.div`
  width: 60%;
  margin: 160px auto 0;
  text-align: center;
  color: white;
  h1 {
    font-size: 60px;
  }
  h3 {
    width: 80%;
    margin: 20px auto 100px;
    font-weight: 100;
    line-height: 25px;
  }
  button {
    width: 200px;
    padding: 15px 0;
    text-align: center;
    margin: 0 10px;
    border-radius: 25px;
    font-weight: bold;
    border: 2px solid #bcaf87;
    background: #bcaf87;
    color: white;
    cursor: pointer;
    transition: background 0.5s;
  }
  button:hover {
    background: transparent;
    border: 2px solid white;
  }
  .btn-2 {
    border: 2px solid white;
    background: transparent;
  }
  .btn-2:hover {
    background: #bcaf87;
    border: 2px solid #bcaf87;
  }
`;

const IMAGES = [image1, image2, image3, image4];

function Hero() {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      if (currentIndex >= IMAGES.length - 1) setCurrentIndex(0);
      else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 3000);
  }, [currentIndex]);
  return (
    <Banner>
      <Slider>
        <SlideImg src={IMAGES[currentIndex]} />
      </Slider>
      <Overlay>
        <Navbar>
          <Logo>
            <img src={logo} alt="logo" />
          </Logo>
          <MenuIcons>
            <MenuIcon color="white" />
          </MenuIcons>
        </Navbar>
        <Content>
          <h1>Restaurant Casa Bella - Draria</h1>
          <h3>
            Découvrez des saveurs authentiques dans une ambiance chaleureuse et
            conviviale.
          </h3>
          <button type="button">Commandez Maintenant</button>
          <button type="button" className="btn-2">
            Rencontrez le chef
          </button>
        </Content>
      </Overlay>
    </Banner>
  );
}

export default Hero;
