import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCH80F-dn5MAI7Jf7hZVDH4rYMJbXxKkrw",
  authDomain: "cognitiq-38860.firebaseapp.com",
  projectId: "cognitiq-38860",
  storageBucket: "cognitiq-38860.appspot.com",
  messagingSenderId: "980700837410",
  appId: "1:980700837410:web:ee2f84ff4cf4e1ddf23c7b",
  measurementId: "G-3D5BX64GC4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export const signupWithEmailAndPassword = async (email, password) => {
  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signupWithGoogle = async () => {
  const provider = new GoogleAuthProvider();

  const result = await signInWithPopup(auth, provider);
  const user = result.user;
  return user;
};

export const getDocument = async (collectionName, docId) => {
  const docRef = doc(db, collectionName, docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) return { ...docSnap.data() };

  return null;
};
