import React from "react";
import GlobalStyles from "styles/GlobalStyles";
import { css } from "styled-components/macro"; //eslint-disable-line

import ComponentRenderer from "ComponentRenderer.js";
import MainLandingPage from "MainLandingPage.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TransLang from "pages/TransLang";
import CognitiQ from "pages/CognitiQ";
import TranslateDoc from "pages/TransLang/TranslateDoc";
import Summarize from "pages/CognitiQ/Summarize";
import Bulletpoints from "pages/CognitiQ/bulletpoints";
import Signup from "pages/Signup";
import RestaurantLandingPage from "demos/RestaurantLandingPage";
import Theme1 from "pages/Restaurant/Theme1";
import Theme2 from "pages/Restaurant/Theme2";
import BlogPage from "pages/BlogPage";

export default function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route
            path="/components/:type/:subtype/:name"
            element={<ComponentRenderer />}
          />
          <Route
            path="/components/:type/:name"
            element={<ComponentRenderer />}
          />
          <Route path="/" element={<MainLandingPage />} />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/restaurant/:restaurantId"
            element={<RestaurantLandingPage />}
          />
          <Route path="/restaurant/:restaurantId/1" element={<Theme1 />} />
          <Route path="/restaurant/:restaurantId/2" element={<Theme2 />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/translang" element={<TransLang />} />
          <Route
            path="/translang/translate-document"
            element={<TranslateDoc />}
          />
          <Route path="/cognitiq" element={<CognitiQ />} />
          <Route path="/cognitiq/summarize" element={<Summarize />} />
          <Route path="/cognitiq/bulletpoints" element={<Bulletpoints />} />
        </Routes>
      </Router>
    </>
  );
}
