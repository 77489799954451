import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import GridWithFeaturedPost from "components/blogs/GridWithFeaturedPost";

function BlogPage() {
  return (
    <AnimationRevealPage>
      <Header />
      <GridWithFeaturedPost />
      <Footer />
    </AnimationRevealPage>
  );
}

export default BlogPage;
