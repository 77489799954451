import React, { useState } from "react";
import tw from "twin.macro";
import translateText from "./utils/translateText";
import LanguageSelectors from "./components/LanguageSelectors";
import Textarea from "components/misc/TextArea";

const Button = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl mb-5`;
const Text = tw.p`ml-2 font-medium text-gray-700 mb-5`;

function SimpleTranslate() {
  const [fromLang, setFromLang] = useState("en");
  const [toLang, setToLang] = useState("fr");
  const [text, setText] = useState("");
  const [translation, setTranslation] = useState("");
  const translate = async () => {
    const translated = await translateText(text, fromLang, toLang);
    setTranslation(translated);
  };
  return (
    <>
      <LanguageSelectors
        setFromLang={setFromLang}
        setToLang={setToLang}
        fromLang={fromLang}
        toLang={toLang}
      />
      <Textarea
        placeholder="Text"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <Button onClick={translate}>Translate</Button>
      <Text>{translation}</Text>
    </>
  );
}

export default SimpleTranslate;
