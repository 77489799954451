import MDrawer from "components/misc/Drawer";
import React from "react";

const links = [
  {
    url: "/translang",
    text: "Translate",
  },
  {
    url: "/translang/translate-document",
    text: "Translate Document",
  },
];
export default function Drawer() {
  return <MDrawer links={links} />;
}
