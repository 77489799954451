import AnimationRevealPage from "helpers/AnimationRevealPage";
import React, { useState } from "react";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Drawer from "components/misc/Drawer";
import Textarea from "components/misc/TextArea";
import { drawerLinks } from "./constants";

function CognitiQ() {
  const [inputText, setInputText] = useState("");
  const answer = "";

  return (
    <AnimationRevealPage>
      <div style={{ paddingLeft: "16rem" }}>
        <Header />
        <Drawer links={drawerLinks} />
        <Textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Start chatting"
        />
        <p>{answer}</p>
        <Footer />
      </div>
    </AnimationRevealPage>
  );
}

export default CognitiQ;
