import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Drawer from "components/misc/Drawer";
import Textarea from "components/misc/TextArea";
import { PrimaryButton } from "components/misc/Buttons";
import axios from "axios";
import { drawerLinks } from "./constants";

function Summarize() {
  const [inputText, setInputText] = useState("");
  const [answer, setAnswer] = useState("");
  const onSubmit = async () => {
    const response = await axios.post("https://cognitiq.vercel.app/summarize", {
      content: inputText,
    });
    setAnswer(response.data.text);
  };
  return (
    <AnimationRevealPage>
      <div style={{ paddingLeft: "16rem" }}>
        <Header />
        <Drawer links={drawerLinks} />
        <Textarea
          placeholder="Text to summarize"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
        />
        <PrimaryButton onClick={onSubmit}>Summarize</PrimaryButton>
        <p>{answer}</p>
        <Footer />
      </div>
    </AnimationRevealPage>
  );
}

export default Summarize;
