export const drawerLinks = [
  {
    url: "/cognitiq",
    text: "Chat",
  },
  {
    url: "/cognitiq/summarize",
    text: "Summarize",
  },
  {
    url: "/cognitiq/bulletpoints",
    text: "BulletPoints",
  },
];
