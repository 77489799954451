import React from "react";
import tw from "twin.macro";

const DrawerContainer = tw.div`fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform bg-white w-64 dark:bg-gray-800`;
const MenuTitle = tw.h5`text-base font-semibold text-gray-500 uppercase dark:text-gray-400`;
const ListContainer = tw.div`py-4 overflow-y-auto`;
const List = tw.ul`space-y-2 font-medium`;
const ItemLink = tw.a`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`;

export default function Drawer({ links = [] }) {
  return (
    <>
      <DrawerContainer
        id="drawer-navigation"
        tabindex="-1"
        aria-labelledby="drawer-navigation-label"
      >
        <MenuTitle id="drawer-navigation-label">Menu</MenuTitle>

        <ListContainer>
          <List>
            {links.map((link) => (
              <ItemLink href={link.url} key={link.url}>
                <span>{link.text}</span>
              </ItemLink>
            ))}
          </List>
        </ListContainer>
      </DrawerContainer>
    </>
  );
}
