import React from "react";
import tw from "twin.macro";

const Select = tw.select`bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`;
const Text = tw.p`ml-2 font-medium text-gray-700`;

const languages = [
  { language: "aa", name_english: "Afar", name_native: "Afar" },
  { language: "ab", name_english: "Abkhazian", name_native: "Аҧсуа" },
  { language: "ace", name_english: "Acehnese", name_native: "Bahsa Acèh" },
  { language: "ach", name_english: "Acholi", name_native: "Lwo" },
  { language: "af", name_english: "Afrikaans", name_native: "Afrikaans" },
  { language: "ak", name_english: "Akan", name_native: "Akan" },
  { language: "alz", name_english: "Alur", name_native: "Luo" },
  { language: "am", name_english: "Amharic", name_native: "አማርኛ" },
  { language: "ar", name_english: "Arabic", name_native: "العربية" },
  { language: "as", name_english: "Assamese", name_native: "অসমীয়া" },
  { language: "av", name_english: "Avaric", name_native: "Магӏарул мацӏ" },
  { language: "awa", name_english: "Awadhi", name_native: "अवधी" },
  { language: "ay", name_english: "Aymara", name_native: "Aymar aru" },
  { language: "az", name_english: "Azerbaijani", name_native: "Azərbaycanca" },
  { language: "ba", name_english: "Bashkir", name_native: "Башҡорт" },
  { language: "bal", name_english: "Balochi", name_native: "بلوچی" },
  { language: "ban", name_english: "Balinese", name_native: "ᬩᬲᬩᬮᬶ" },
  {
    language: "bbc",
    name_english: "Batak Toba",
    name_native: "Hata Batak Toba",
  },
  { language: "bci", name_english: "Bete", name_native: "Bété" },
  { language: "be", name_english: "Belarusian", name_native: "Беларуская" },
  { language: "bem", name_english: "Bemba", name_native: "ChiBemba" },
  { language: "ber", name_english: "Berber", name_native: "ⴰⵎⵓⵔⴰⵖ" },
  {
    language: "ber-Latn",
    name_english: "Berber (Latin script)",
    name_native: "Tamazight",
  },
  { language: "bew", name_english: "Betawi", name_native: "Bahasa Betawi" },
  { language: "bg", name_english: "Bulgarian", name_native: "Български" },
  { language: "bho", name_english: "Bhojpuri", name_native: "भोजपुरी" },
  { language: "bik", name_english: "Bikol", name_native: "Bikol" },
  { language: "bm", name_english: "Bambara", name_native: "Bamanankan" },
  {
    language: "bm-Nkoo",
    name_english: "Bambara (N'Ko script)",
    name_native: "ߓߡߊߣߊߣߞߊߣ",
  },
  { language: "bn", name_english: "Bengali", name_native: "বাংলা" },
  { language: "bo", name_english: "Tibetan", name_native: "བོད་སྐད་" },
  { language: "br", name_english: "Breton", name_native: "Brezhoneg" },
  { language: "bs", name_english: "Bosnian", name_native: "Bosanski" },
  {
    language: "bts",
    name_english: "Batak Simalungun",
    name_native: "Hata Simalungun",
  },
  { language: "btx", name_english: "Batak Karo", name_native: "Cakap Karo" },
  { language: "bua", name_english: "Buryat", name_native: "Буряад хэлэн" },
  { language: "ca", name_english: "Catalan", name_native: "Català" },
  { language: "ce", name_english: "Chechen", name_native: "Нохчийн" },
  { language: "ceb", name_english: "Cebuano", name_native: "Binisaya" },
  { language: "cgg", name_english: "Chiga", name_native: "Rukiga" },
  { language: "ch", name_english: "Chamorro", name_native: "Finu' Chamoru" },
  { language: "chk", name_english: "Chuukese", name_native: "Chuuk" },
  { language: "chm", name_english: "Mari", name_native: "Марий" },
  { language: "ckb", name_english: "Sorani Kurdish", name_native: "کوردی" },
  { language: "cnh", name_english: "Chin Haka", name_native: "Lai" },
  { language: "co", name_english: "Corsican", name_native: "Corsu" },
  {
    language: "crh",
    name_english: "Crimean Tatar",
    name_native: "Qırımtatar tili",
  },
  {
    language: "crs",
    name_english: "Seychellois Creole",
    name_native: "Kreol Seselwa",
  },
  { language: "cs", name_english: "Czech", name_native: "Čeština" },
  { language: "cv", name_english: "Chuvash", name_native: "Чӑваш" },
  { language: "cy", name_english: "Welsh", name_native: "Cymraeg" },
  { language: "da", name_english: "Danish", name_native: "Dansk" },
  { language: "de", name_english: "German", name_native: "Deutsch" },
  { language: "din", name_english: "Dinka", name_native: "Thuɔŋjäŋ" },
  { language: "doi", name_english: "Dogri", name_native: "डोगरी" },
  { language: "dov", name_english: "Dombe", name_native: "Chidombe" },
  { language: "dv", name_english: "Divehi", name_native: "ދިވެހި" },
  { language: "dyu", name_english: "Dyula", name_native: "Julakan" },
  { language: "dz", name_english: "Dzongkha", name_native: "རྫོང་ཁ" },
  { language: "ee", name_english: "Ewe", name_native: "Eʋegbe" },
  { language: "el", name_english: "Greek", name_native: "Ελληνικά" },
  { language: "en", name_english: "English", name_native: "English" },
  { language: "eo", name_english: "Esperanto", name_native: "Esperanto" },
  { language: "es", name_english: "Spanish", name_native: "Español" },
  { language: "et", name_english: "Estonian", name_native: "Eesti" },
  { language: "eu", name_english: "Basque", name_native: "Euskara" },
  { language: "fa", name_english: "Persian", name_native: "فارسی" },
  { language: "fa-AF", name_english: "Dari", name_native: "دری" },
  { language: "ff", name_english: "Fulah", name_native: "Fulfulde" },
  { language: "fi", name_english: "Finnish", name_native: "Suomi" },
  { language: "fj", name_english: "Fijian", name_native: "Na Vosa Vakaviti" },
  { language: "fo", name_english: "Faroese", name_native: "Føroyskt" },
  { language: "fon", name_english: "Fon", name_native: "Fɔ̀ngbè" },
  { language: "fr", name_english: "French", name_native: "Français" },
  { language: "fur", name_english: "Friulian", name_native: "Furlan" },
  { language: "fy", name_english: "Western Frisian", name_native: "Frysk" },
  { language: "ga", name_english: "Irish", name_native: "Gaeilge" },
  { language: "gaa", name_english: "Ga", name_native: "Ga" },
  { language: "gd", name_english: "Scottish Gaelic", name_native: "Gàidhlig" },
  { language: "gl", name_english: "Galician", name_native: "Galego" },
  { language: "gn", name_english: "Guarani", name_native: "Avañe'ẽ" },
  {
    language: "gom",
    name_english: "Goan Konkani",
    name_native: "Gõychi Konknni",
  },
  { language: "gu", name_english: "Gujarati", name_native: "ગુજરાતી" },
  { language: "gv", name_english: "Manx", name_native: "Gaelg" },
  { language: "ha", name_english: "Hausa", name_native: "Hausa" },
  { language: "haw", name_english: "Hawaiian", name_native: "ʻŌlelo Hawaiʻi" },
  { language: "he", name_english: "Hebrew", name_native: "עברית" },
  { language: "hi", name_english: "Hindi", name_native: "हिन्दी" },
  { language: "hil", name_english: "Hiligaynon", name_native: "Ilonggo" },
  { language: "hmn", name_english: "Hmong", name_native: "Hmoob" },
  { language: "hr", name_english: "Croatian", name_native: "Hrvatski" },
  { language: "hrx", name_english: "Hunsrik", name_native: "Hunsrückisch" },
  {
    language: "ht",
    name_english: "Haitian Creole",
    name_native: "Kreyòl Ayisyen",
  },
  { language: "hu", name_english: "Hungarian", name_native: "Magyar" },
  { language: "hy", name_english: "Armenian", name_native: "Հայերեն" },
  { language: "iba", name_english: "Iban", name_native: "Iban" },
  {
    language: "id",
    name_english: "Indonesian",
    name_native: "Bahasa Indonesia",
  },
  { language: "ig", name_english: "Igbo", name_native: "Asụsụ Igbo" },
  { language: "ilo", name_english: "Ilocano", name_native: "Ilokano" },
  { language: "is", name_english: "Icelandic", name_native: "Íslenska" },
  { language: "it", name_english: "Italian", name_native: "Italiano" },
  { language: "iw", name_english: "Yiddish", name_native: "ייִדיש" },
  { language: "ja", name_english: "Japanese", name_native: "日本語" },
  {
    language: "jam",
    name_english: "Jamaican Patois",
    name_native: "Jamaican Creole",
  },
  { language: "jv", name_english: "Javanese", name_native: "ꦧꦱꦗꦮ" },
  {
    language: "jw",
    name_english: "Javanese (Latin script)",
    name_native: "Basa Jawa",
  },
  { language: "ka", name_english: "Georgian", name_native: "ქართული" },
  { language: "kac", name_english: "Kachin", name_native: "Jingpho" },
  { language: "kek", name_english: "Kekchi", name_native: "Q'eqchi'" },
  { language: "kg", name_english: "Kongo", name_native: "Kikongo" },
  { language: "kha", name_english: "Khasi", name_native: "Ka Ktien Khasi" },
  { language: "kk", name_english: "Kazakh", name_native: "Қазақша" },
  { language: "kl", name_english: "Greenlandic", name_native: "Kalaallisut" },
  { language: "km", name_english: "Khmer", name_native: "ភាសាខ្មែរ" },
  { language: "kn", name_english: "Kannada", name_native: "ಕನ್ನಡ" },
  { language: "ko", name_english: "Korean", name_native: "한국어" },
  { language: "kr", name_english: "Kanuri", name_native: "Kanuri" },
  { language: "kri", name_english: "Krio", name_native: "Krio" },
  { language: "ktu", name_english: "Kanembu", name_native: "Kanembu" },
  { language: "ku", name_english: "Kurdish", name_native: "Kurdî" },
  { language: "kv", name_english: "Komi", name_native: "Коми" },
  { language: "ky", name_english: "Kyrgyz", name_native: "Кыргызча" },
  { language: "la", name_english: "Latin", name_native: "Latine" },
  {
    language: "lb",
    name_english: "Luxembourgish",
    name_native: "Lëtzebuergesch",
  },
  { language: "lg", name_english: "Ganda", name_native: "Luganda" },
  { language: "li", name_english: "Limburgish", name_native: "Limburgs" },
  { language: "lij", name_english: "Ligurian", name_native: "Ligure" },
  { language: "lmo", name_english: "Lombard", name_native: "Lumbaart" },
  { language: "ln", name_english: "Lingala", name_native: "Lingála" },
  { language: "lo", name_english: "Lao", name_native: "ລາວ" },
  { language: "lt", name_english: "Lithuanian", name_native: "Lietuvių" },
  { language: "ltg", name_english: "Latgalian", name_native: "Latgaļu" },
  { language: "luo", name_english: "Luo", name_native: "Dholuo" },
  { language: "lus", name_english: "Mizo", name_native: "Mizo ṭawng" },
  { language: "lv", name_english: "Latvian", name_native: "Latviešu" },
  { language: "mad", name_english: "Madurese", name_native: "Basa Madhura" },
  { language: "mai", name_english: "Maithili", name_native: "मैथिली" },
  {
    language: "mak",
    name_english: "Makassarese",
    name_native: "Basa Mangkasara",
  },
  { language: "mam", name_english: "Mam", name_native: "Qyool Mam" },
  {
    language: "mfe",
    name_english: "Mauritian Creole",
    name_native: "Kreol Morisien",
  },
  { language: "mg", name_english: "Malagasy", name_native: "Malagasy" },
  { language: "mh", name_english: "Marshallese", name_native: "Kajin M̧ajeļ" },
  { language: "mi", name_english: "Māori", name_native: "Te Reo Māori" },
  {
    language: "min",
    name_english: "Minangkabau",
    name_native: "Baso Minangkabau",
  },
  { language: "mk", name_english: "Macedonian", name_native: "Македонски" },
  { language: "ml", name_english: "Malayalam", name_native: "മലയാളം" },
  { language: "mn", name_english: "Mongolian", name_native: "Монгол" },
  { language: "mni-Mtei", name_english: "Manipuri", name_native: "ꯃꯤꯇꯩ ꯂꯣꯟ" },
  { language: "mr", name_english: "Marathi", name_native: "मराठी" },
  { language: "ms", name_english: "Malay", name_native: "Bahasa Melayu" },
  {
    language: "ms-Arab",
    name_english: "Malay (Arabic script)",
    name_native: "بهاس ملايو",
  },
  { language: "mt", name_english: "Maltese", name_native: "Malti" },
  { language: "mwr", name_english: "Marwari", name_native: "मारवाड़ी" },
  { language: "my", name_english: "Burmese", name_native: "မြန်မာစာ" },
  { language: "na", name_english: "Nauruan", name_native: "Dorerin Naoero" },
  { language: "nan", name_english: "Min Nan Chinese", name_native: "閩南語" },
  { language: "nap", name_english: "Neapolitan", name_native: "Napulitano" },
  { language: "nb", name_english: "Norwegian Bokmål", name_native: "Bokmål" },
  { language: "nd", name_english: "North Ndebele", name_native: "isiNdebele" },
  { language: "ne", name_english: "Nepali", name_native: "नेपाली" },
  { language: "ng", name_english: "Ndonga", name_native: "Oshindonga" },
  { language: "nl", name_english: "Dutch", name_native: "Nederlands" },
  { language: "nmg", name_english: "Kwasio", name_native: "Ngumba" },
  { language: "nn", name_english: "Norwegian Nynorsk", name_native: "Nynorsk" },
  { language: "nr", name_english: "South Ndebele", name_native: "isiNdebele" },
  {
    language: "nso",
    name_english: "Northern Sotho",
    name_native: "Sesotho sa Leboa",
  },
  { language: "nus", name_english: "Nuer", name_native: "Thok Nath" },
  { language: "ny", name_english: "Chichewa", name_native: "Nyanja" },
  { language: "oc", name_english: "Occitan", name_native: "Occitan" },
  { language: "om", name_english: "Oromo", name_native: "Afaan Oromoo" },
  { language: "or", name_english: "Odia", name_native: "ଓଡ଼ିଆ" },
  { language: "os", name_english: "Ossetic", name_native: "Ирон æвзаг" },
  { language: "pa", name_english: "Punjabi", name_native: "ਪੰਜਾਬੀ" },
  { language: "pag", name_english: "Pangasinan", name_native: "Pangasinense" },
  { language: "pam", name_english: "Kapampangan", name_native: "Kapampangan" },
  { language: "pap", name_english: "Papiamento", name_native: "Papiamento" },
  {
    language: "pau",
    name_english: "Palauan",
    name_native: "A tekoi er a Belau",
  },
  { language: "pl", name_english: "Polish", name_native: "Polski" },
  { language: "pms", name_english: "Piedmontese", name_native: "Piemontèis" },
  { language: "pnb", name_english: "Western Punjabi", name_native: "پنجابی" },
  { language: "prg", name_english: "Old Prussian", name_native: "Prūsiskan" },
  { language: "ps", name_english: "Pashto", name_native: "پښتو" },
  { language: "pt", name_english: "Portuguese", name_native: "Português" },
  { language: "qu", name_english: "Quechua", name_native: "Runa Simi" },
  { language: "qug", name_english: "Quichua", name_native: "Quichua" },
  {
    language: "rap",
    name_english: "Rapa Nui",
    name_native: "Vananga rapa nui",
  },
  {
    language: "rcf",
    name_english: "Réunion Creole",
    name_native: "Kréol Rénioné",
  },
  { language: "rif", name_english: "Riffian", name_native: "ⴰⵔⵉⴼⵉⵢⴻⵏ" },
  { language: "rm", name_english: "Romansh", name_native: "Rumantsch" },
  { language: "rn", name_english: "Kirundi", name_native: "Ikirundi" },
  { language: "ro", name_english: "Romanian", name_native: "Română" },
  { language: "roa-tara", name_english: "Tarantino", name_native: "Tarantino" },
  { language: "ru", name_english: "Russian", name_native: "Русский" },
  { language: "rw", name_english: "Kinyarwanda", name_native: "Kinyarwanda" },
  { language: "sa", name_english: "Sanskrit", name_native: "संस्कृतम्" },
  { language: "sah", name_english: "Sakha", name_native: "Саха тыла" },
  { language: "sat", name_english: "Santali", name_native: "ᱥᱟᱱᱛᱟᱲᱤ" },
  { language: "sc", name_english: "Sardinian", name_native: "Sardu" },
  { language: "scn", name_english: "Sicilian", name_native: "Sicilianu" },
  { language: "sd", name_english: "Sindhi", name_native: "سنڌي" },
  { language: "sg", name_english: "Sango", name_native: "Sängö" },
  { language: "sgs", name_english: "Samogitian", name_native: "Žemaitėška" },
  { language: "shn", name_english: "Shan", name_native: "ၵႂၢမ်းတႆး" },
  { language: "si", name_english: "Sinhala", name_native: "සිංහල" },
  { language: "sk", name_english: "Slovak", name_native: "Slovenčina" },
  { language: "sl", name_english: "Slovenian", name_native: "Slovenščina" },
  { language: "sm", name_english: "Samoan", name_native: "Gagana Samoa" },
  { language: "sn", name_english: "Shona", name_native: "ChiShona" },
  { language: "so", name_english: "Somali", name_native: "Af-Soomaali" },
  { language: "sq", name_english: "Albanian", name_native: "Shqip" },
  { language: "sr", name_english: "Serbian", name_native: "Српски" },
  {
    language: "sr-Latn",
    name_english: "Serbian (Latin script)",
    name_native: "Srpski",
  },
  { language: "ss", name_english: "Swati", name_native: "SiSwati" },
  { language: "st", name_english: "Southern Sotho", name_native: "Sesotho" },
  { language: "su", name_english: "Sundanese", name_native: "Basa Sunda" },
  {
    language: "su-Latn",
    name_english: "Sundanese (Latin script)",
    name_native: "Basa Sunda",
  },
  { language: "sv", name_english: "Swedish", name_native: "Svenska" },
  { language: "sw", name_english: "Swahili", name_native: "Kiswahili" },
  { language: "szl", name_english: "Silesian", name_native: "Ślōnskŏ gŏdka" },
  { language: "ta", name_english: "Tamil", name_native: "தமிழ்" },
  { language: "tay", name_english: "Tayal", name_native: "Tayal" },
  { language: "te", name_english: "Telugu", name_native: "తెలుగు" },
  { language: "tet", name_english: "Tetum", name_native: "Lia-Tetun" },
  { language: "tg", name_english: "Tajik", name_native: "Тоҷикӣ" },
  { language: "th", name_english: "Thai", name_native: "ไทย" },
  { language: "ti", name_english: "Tigrinya", name_native: "ትግርኛ" },
  { language: "tk", name_english: "Turkmen", name_native: "Türkmençe" },
  { language: "tl", name_english: "Tagalog", name_native: "Tagalog" },
  { language: "tlh", name_english: "Klingon", name_native: "tlhIngan Hol" },
  { language: "tn", name_english: "Tswana", name_native: "Setswana" },
  { language: "to", name_english: "Tongan", name_native: "Lea Faka-Tonga" },
  { language: "tpi", name_english: "Tok Pisin", name_native: "Tok Pisin" },
  { language: "tr", name_english: "Turkish", name_native: "Türkçe" },
  { language: "ts", name_english: "Tsonga", name_native: "Xitsonga" },
  { language: "tt", name_english: "Tatar", name_native: "Татарча" },
  { language: "tum", name_english: "Tumbuka", name_native: "chiTumbuka" },
  {
    language: "tvl",
    name_english: "Tuvaluan",
    name_native: "Te Ggana Tuuvalu",
  },
  { language: "tw", name_english: "Twi", name_native: "Twi" },
  { language: "ty", name_english: "Tahitian", name_native: "Reo Tahiti" },
  {
    language: "tzm",
    name_english: "Central Atlas Tamazight",
    name_native: "ⵜⴰⵎⴰⵣⵉⵖⵜ",
  },
  { language: "udm", name_english: "Udmurt", name_native: "Удмурт кыл" },
  { language: "ug", name_english: "Uyghur", name_native: "ئۇيغۇر تىلى" },
  { language: "uk", name_english: "Ukrainian", name_native: "Українська" },
  { language: "ur", name_english: "Urdu", name_native: "اردو" },
  { language: "uz", name_english: "Uzbek", name_native: "O'zbekcha" },
  { language: "vec", name_english: "Venetian", name_native: "Vèneto" },
  { language: "vep", name_english: "Veps", name_native: "Vepsän kel’" },
  { language: "vi", name_english: "Vietnamese", name_native: "Tiếng Việt" },
  { language: "vls", name_english: "West Flemish", name_native: "West-Vlams" },
  { language: "vo", name_english: "Volapük", name_native: "Volapük" },
  { language: "vro", name_english: "Võro", name_native: "Võro" },
  { language: "wa", name_english: "Walloon", name_native: "Walon" },
  { language: "war", name_english: "Waray", name_native: "Winaray" },
  { language: "wls", name_english: "Wallisian", name_native: "Faka'uvea" },
  { language: "wo", name_english: "Wolof", name_native: "Wolof" },
  { language: "wuu", name_english: "Wu Chinese", name_native: "吴语" },
  { language: "xh", name_english: "Xhosa", name_native: "isiXhosa" },
  { language: "yi", name_english: "Yiddish", name_native: "ייִדיש" },
  { language: "yo", name_english: "Yoruba", name_native: "Yorùbá" },
  { language: "yua", name_english: "Yucatec Maya", name_native: "Màaya t'àan" },
  { language: "yue", name_english: "Cantonese", name_native: "粵語" },
  { language: "za", name_english: "Zhuang", name_native: "Saɯ cueŋƅ" },
  { language: "zh", name_english: "Chinese", name_native: "中文" },
  {
    language: "zh_classical",
    name_english: "Classical Chinese",
    name_native: "文言",
  },
  {
    language: "zh-Hans",
    name_english: "Simplified Chinese",
    name_native: "简体中文",
  },
  {
    language: "zh-Hant",
    name_english: "Traditional Chinese",
    name_native: "繁體中文",
  },
  { language: "zu", name_english: "Zulu", name_native: "isiZulu" },
];

function LanguageSelectors({ setFromLang, setToLang, fromLang, toLang }) {
  return (
    <div
      style={{
        padding: "3rem",
        display: "flex",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <Text>Translate from</Text>
      <Select value={fromLang} onChange={(e) => setFromLang(e.target.value)}>
        {languages.map((language) => (
          <option key={`from${language.language}`} value={language.language}>
            {`${language.name_native}, ${language.name_english}`}
          </option>
        ))}
      </Select>
      <Text>to</Text>
      <Select value={toLang} onChange={(e) => setToLang(e.target.value)}>
        {languages.map((language) => (
          <option key={`to${language.language}`} value={language.language}>
            {`${language.name_native}, ${language.name_english}`}
          </option>
        ))}
      </Select>
    </div>
  );
}

export default LanguageSelectors;
