import styled from "styled-components";
import tw from "twin.macro";

const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;

const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-full w-full`}
`;

export default Textarea;
