import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Drawer from "components/misc/Drawer";
import Textarea from "components/misc/TextArea";
import { PrimaryButton } from "components/misc/Buttons";
import axios from "axios";
import { drawerLinks } from "./constants";
import Markdown from "react-markdown";

function Bulletpoints() {
  const [inputText, setInputText] = useState("");
  const [answer, setAnswer] = useState([]);
  const onSubmit = async () => {
    const response = await axios.post(
      "https://cognitiq.vercel.app/bulletpoints",
      {
        content: inputText,
      }
    );
    setAnswer(response.data);
  };
  return (
    <AnimationRevealPage>
      <div style={{ paddingLeft: "16rem" }}>
        <Header />
        <Drawer links={drawerLinks} />
        <Textarea
          placeholder="Write or paste your text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
        />
        {answer.map((item) => (
          <Markdown key={item.title}>{item.title}</Markdown>
        ))}
        <PrimaryButton onClick={onSubmit}>Get BulletPoints</PrimaryButton>
        <Footer />
      </div>
    </AnimationRevealPage>
  );
}

export default Bulletpoints;
