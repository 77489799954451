import React from "react";
import Hero from "./Hero";
import tw from "twin.macro";
import MainFeature from "./MainFeature";
import styled from "styled-components";

const Description = styled.p`
  font-family: "Jost";
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: rgb(39, 47, 60);
`;
const imageCss = tw`rounded-4xl`;

function Theme1() {
  return (
    <div>
      <Hero />
      <MainFeature
        description={
          <Description>
            Situé au cœur d'Alger, Restaurant vous invite à savourer une cuisine
            raffinée où chaque plat est préparé avec des ingrédients frais et
            locaux.
            <br />
            <br />
            Que ce soit pour un dîner en amoureux, une réunion de famille ou une
            soirée entre amis, notre équipe vous garantit une expérience
            gastronomique unique.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="dernières offres"
        imageSrc={
          "https://images.unsplash.com/photo-1460306855393-0410f61241c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
    </div>
  );
}

export default Theme1;
