import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import emailjs from "@emailjs/browser";

import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import ReactModalAdapter from "helpers/ReactModalAdapter";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { useNavigate } from "react-router-dom";

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
  subheading = "Contactez Nous",
  heading = (
    <>
      N'hésitez pas à nous <span tw="text-primary-500">contacter</span>
      <wbr /> maintenant.
    </>
  ),
  description = "Nous serions ravis de discuter avec vous de vos besoins en matière de création de pages de destination. Contactez-nous via le formulaire ci-dessous, par téléphone ou par email, et nous reviendrons vers vous dans les plus brefs délais.",
  submitButtonText = "Envoyer",

  textOnLeft = true,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submitting");
    emailjs
      .sendForm(
        "service_58vvyeb",
        "template_o1x1yte",
        e.target,
        "dxD3jeOLToJILfRk6"
      )
      .then(() => toggleModal());
  };
  const navigate = useNavigate();
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={handleSubmit}>
              <Input type="email" name="email" placeholder="Email" required />
              <Input
                type="text"
                name="name"
                placeholder="Nom complet"
                required
              />
              <Input
                type="tel"
                name="phone"
                placeholder="Numéro de téléphone"
                required
              />
              <Input
                type="text"
                name="bname"
                placeholder="Nom de l'entreprise"
                required
              />
              <Input type="text" name="subject" placeholder="Sujet" required />
              <Textarea name="message" placeholder="Message" required />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
      <StyledModal
        closeTimeoutMS={300}
        className="mainHeroModal"
        isOpen={modalIsOpen}
        onRequestClose={() => {
          toggleModal();
          console.log("closing");
          navigate("/");
        }}
        shouldCloseOnOverlayClick={true}
      >
        <CloseModalButton onClick={toggleModal}>
          <CloseIcon tw="w-6 h-6" />
        </CloseModalButton>
        <div className="content">
          <Heading>Merci pour votre message !</Heading>
          <Description>
            Nous avons bien reçu votre message et vous remercions de nous avoir
            contactés. Nous reviendrons vers vous dans les plus brefs délais. En
            attendant, vous pouvez parcourir notre site pour en savoir plus sur
            nos services.
            <br />
            Cordialement,
            <br />
            L'équipe AcademySites Algeria
          </Description>
        </div>
      </StyledModal>
    </Container>
  );
};
