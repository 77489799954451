import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>À propos de AcademySites</Subheading>}
        heading="Chez AcademySites Algeria"
        description="Nous sommes dédiés à transformer la présence en ligne des entreprises algériennes grâce à des pages de destination innovantes et personnalisées. Notre mission est de fournir des solutions web de haute qualité qui répondent aux besoins uniques de chaque client."
        buttonRounded={false}
        primaryButtonText="Voir le portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Notre Mission</Subheading>}
        heading="Notre mission est de créer"
        description="Des pages de destination efficaces qui aident les entreprises à atteindre leurs objectifs marketing et à maximiser leur retour sur investissement. Nous nous engageons à offrir des designs modernes, une expérience utilisateur optimale et des fonctionnalités avancées."
        buttonRounded={false}
        primaryButtonText="Nous contacter"
        primaryButtonUrl="/components/innerPages/ContactUsPage"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Nos Valeurs</Subheading>}
        heading="Nous suivons ces valeurs"
        description="Chez AcademySites Algeria, nous croyons fermement que des valeurs solides sont la clé de la réussite. Nos valeurs fondamentales guident chaque aspect de notre travail et définissent la manière dont nous interagissons avec nos clients, nos partenaires et notre communauté."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Service Client",
            description:
              "Nous plaçons nos clients au centre de notre démarche. Nous sommes à l'écoute de leurs besoins et nous nous engageons à leur fournir un service personnalisé et réactif. Notre objectif est de bâtir des relations durables fondées sur la confiance et la satisfaction.",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Qualité",
            description:
              "La qualité est au cœur de tout ce que nous faisons. De la conception à la réalisation, chaque détail est soigneusement pris en compte pour garantir des résultats impeccables. Nous nous efforçons d'atteindre l'excellence à chaque étape du processus.",
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Engagement",
            description:
              "Nous sommes passionnés par ce que nous faisons et nous nous engageons pleinement dans chaque projet. Notre dévouement et notre persévérance nous permettent de surmonter les défis et de livrer des solutions qui dépassent les attentes de nos clients.",
          },
        ]}
        linkText=""
      />
      <TeamCardGrid subheading={<Subheading>Notre Équipe</Subheading>} />
      <Footer />
    </AnimationRevealPage>
  );
};
