import React from "react";
import Drawer from "./components/Drawer";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import SimpleTranslate from "./SimpleTranslate";

export default function TransLang() {
  return (
    <AnimationRevealPage>
      <div style={{ paddingLeft: "16rem" }}>
        <Header />
        <Drawer />
        <SimpleTranslate />

        <Footer />
      </div>
    </AnimationRevealPage>
  );
}
