import axios from "axios";
import qs from "qs";

const getGoogleOptions = (text, source, target) => {
  return {
    method: "POST",
    url: "https://google-translate1.p.rapidapi.com/language/translate/v2",
    data: qs.stringify({ q: text, source, target }),
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      "accept-encoding": "application/gzip",
      "x-rapidapi-key": "519644ac9amsh03cabecbd3b6b6dp105736jsn2c46bdbe47bd",
      "x-rapidapi-host": "google-translate1.p.rapidapi.com",
    },
  };
};

const getMicrosoftOptions = (text, source, target) => {
  return {
    method: "POST",
    url: "https://microsoft-translator-text.p.rapidapi.com/translate",
    params: {
      from: source,
      to: target,
      "api-version": "3.0",
      profanityAction: "NoAction",
      textType: "plain",
    },
    headers: {
      "x-rapidapi-key": "519644ac9amsh03cabecbd3b6b6dp105736jsn2c46bdbe47bd",
      "x-rapidapi-host": "microsoft-translator-text.p.rapidapi.com",
      "Content-Type": "application/json",
    },
    data: [
      {
        Text: text,
      },
    ],
  };
};

const translateText = async (text, source, target) => {
  let isSuccess = true;
  let translated = "";
  try {
    const response = await axios.request(
      getGoogleOptions(text, source, target)
    );
    isSuccess = true;
    translated = response.data.data["translations"][0]["translatedText"];
  } catch (error) {
    isSuccess = false;
  }
  if (isSuccess) return translated;
  try {
    const response = await axios.request(
      getMicrosoftOptions(text, source, target)
    );
    isSuccess = true;
    translated = response.data[0].translations[0].text;
  } catch (error) {
    isSuccess = false;
  }
  if (isSuccess) return translated;

  return text;
};

export default translateText;
